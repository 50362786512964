import React, { useEffect, useState, useRef } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import useReturnIntersecting from "../hooks/Hooks";
import { photos } from "./staticData/CarouselPics";
import { SectionHeader } from "./Services";
import { Progress } from "flowbite-react";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

import "../styles/gallery.css";
import "@splidejs/react-splide/css";

const Gallery = ({ setActive }) => {
	const galleryRef = useRef(null);
	const isIntersecting = useReturnIntersecting(galleryRef);
	const [slideProgress, setSlideProgress] = useState(1);

	useEffect(() => {
		if (isIntersecting) {
			setActive("Gallery");
		}
	}, [setActive, isIntersecting]);

	const splideOptions = {
		type: "loop",
		autoplay: true,
		perPage: 1,
		gap: ".5rem",
		lazyload: "nearby",
		rewind: true,
	};

	const dataLength = photos.length;
	const handleMoved = (splide) => {
		let activeIndex = null;
		const ratio = 100 / dataLength;
		if (splide.index === 0) {
			setSlideProgress(1);
			return;
		} else if (splide.index === dataLength - 1) {
			setSlideProgress(100);
			return;
		}
		activeIndex = splide.index * ratio;
		setSlideProgress(activeIndex);
	};

	return (
		<section
			id='Gallery'
			ref={galleryRef}
			className='w-full gallery-main relative px-2 my-8'
		>
			<div className='flex flex-col mx-auto justify-center items-center container'>
				<SectionHeader headline='Our Work' />

				<ProgressBar amount={slideProgress} />

				<Splide
					hasTrack={false}
					options={splideOptions}
					className='h-full w-full'
					aria-labelledby='splide-label'
					onMoved={handleMoved}
				>
					<SplideTrack className='gallery-splide-track'>
						{photos.map((pic, i) => (
							<SplideSlide
								key={pic.id}
								className='h-full py-2'
							>
								<SplideCard pic={pic} />
							</SplideSlide>
						))}
					</SplideTrack>
					<SplideArrows />
				</Splide>
			</div>
		</section>
	);
};

const ProgressBar = ({ amount }) => {
	const progressOptions = {
		base: "w-full overflow-hidden rounded-full bg-shark-50",
		label: "mb-1 flex justify-between font-medium",
		bar: "space-x-2 rounded-full text-center font-medium leading-none !bg-shark-700",
		size: {
			sm: "h-1.5",
		},
	};

	return (
		<div className='relative w-full md:max-w-[42rem] lg:max-w-2xl'>
			<Progress
				theme={progressOptions}
				progress={amount}
				size='sm'
			/>
		</div>
	);
};

const SplideCard = ({ pic }) => (
	<div
		className='rounded-md relative mx-auto flex max-h-[45rem] flex-col shadow-md lg:max-w-2xl bg-contain'
		data-testid='flowbite-card'
	>
		<img
			src={pic.image}
			alt={pic.title}
			data-splide-lazy={pic.image}
			className='rounded-t-md bg-contain flex-1 max-h-[32rem]'
		/>
		<div className='flex flex-col rounded-b-md h-full flex-1 p-4 gap-y-2 border border-t-[#fca311]'>
			<h5 className='md:text-xl text-lg font-bold text-shark'>
				{pic.title}
			</h5>
			<p className='font-normal text-shark-700 text-base md:text-lg rounded-b-md flex items-center gap-x-2'>
				<IoLocationSharp className='text-orange' /> {pic.location}
			</p>
		</div>
	</div>
);

const SplideArrows = () => (
	<div className='splide__arrows mt-2 mb-8 mx-auto relative w-40'>
		<button className='splide__arrow splide__arrow--prev bg-transparent'>
			<IoIosArrowRoundBack className='splideArrow splideArrowLeft' />
		</button>
		<button className='splide__arrow splide__arrow--next bg-transparent'>
			<IoIosArrowRoundForward className='splideArrow splideArrowRight' />
		</button>
	</div>
);

export default Gallery;
