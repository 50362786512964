export const photos = [
	// {
	// 	id: 1,
	// 	title: "LaGuardia Airport",
	// 	image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/GTSpic04.webp",
	// 	location: "Queens, NY",
	// },
	{
		id: 2,
		title: "40th Precinct",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/40th%20precinct.webp",
		location: "Bronx, NY",
	},
	{
		id: 3,
		title: "Atlantic ave Bridge & Van Wyck EXPY",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/atlantic%20ave%20bridge.webp",
		location: "Queens, NY",
	},
	{
		id: 4,
		title: "MTA Clifton Yards",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/mta%20clifton%20yards.webp",
		location: "Staten Island, NY",
	},
	{
		id: 5,
		title: "PS 053",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/ps053.webp",
		location: "Bronx, NY",
	},
	{
		id: 6,
		title: "Borden ave Pump Station",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/borden%20avenue%20pump%20station.webp",
		location: "Long Island City, NY",
	},
	{
		id: 7,
		title: "Fashion Institute of Technology",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/fashion%20institute%20of%20technology.webp",
		location: "New York, NY",
	},
	{
		id: 8,
		title: "JFK Terminal 6",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/GTSpic08.webp",
		location: "Jamaica Queens, NY",
	},
	{
		id: 9,
		title: "Tangram Plaza",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/GTSpic09.webp",
		location: "Queens, NY",
	},
	{
		id: 10,
		title: "Brookhaven National Laboratory",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/brookhaven%20national%20laboratory.webp",
		location: "Upton, NY",
	},
	{
		id: 11,
		title: "LaGuardia Community College",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/laguardia%20community%20college.webp",
		location: "Queens, NY",
	},
	{
		id: 12,
		title: "MTA Sutphin blvd",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/mta%20sutphin%20blvd.webp",
		location: "Queens, NY",
	},
	{
		id: 13,
		title: "PS 746",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/ps256.webp",
		location: "Sunset Park, NY",
	},
	{
		id: 14,
		title: "Sandy Recovery",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/sandy%20recovery%20coney%20island.webp",
		location: "Coney Island, NY",
	},
	{
		id: 15,
		title: "Staten Island Garage",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/staten%20island%20garage.webp",
		location: "Staten Island, NY",
	},
	{
		id: 16,
		title: "Tangram Plaza Skylight",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/tangram%20plaza3.webp",
		location: "Queens, NY",
	},
	{
		id: 17,
		title: "Sunshine Children's Home",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/sunshineChildrenHome.webp",
		location: "Ossining, NY",
	},
	// TODO: Replace this picture
	// {
	// 	id: 18,
	// 	title: "PS 746",
	// 	image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/ps746.webp",
	// 	location: "Sunset Park, NY",
	// },
	{
		id: 19,
		title: "PS 487",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/ps487.webp",
		location: "Bronx, NY",
	},
	{
		id: 20,
		title: "PS 163X",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/ps163x.webp",
		location: "Bronx, NY",
	},
	{
		id: 21,
		title: "Benjamin Cardozo HS",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/bc1.webp",
		location: "Queens, NY",
	},
	{
		id: 22,
		title: "Benjamin Cardozo HS",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/bc3.webp",
		location: "Queens, NY",
	},
	{
		id: 23,
		title: "Benjamin Cardozo HS",
		image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/bc4.webp",
		location: "Queens, NY",
	},
	{
		id: 24,
		title: "Benjamin Cardozo HS",
		image: "https://guytecsteel.nyc3.cdn.digitaloceanspaces.com/carouselWebP/bc7.webp",
		location: "Queens, NY",
	},
];
