import React from "react";
import ScrollIntoView from "react-scroll-into-view";
import gtsLogo from "../assets/svg/gtsLogo.svg";

import "../styles/navbar.css";

const Nav = ({ active, setActive }) => {
	const navLinks = [
		{ id: 1, name: "Home" },
		{ id: 2, name: "Services" },
		{ id: 3, name: "About" },
		{ id: 4, name: "Locations" },
		{ id: 5, name: "Gallery" },
		{ id: 7, name: "Contact" },
	];

	const getStyle = (name) => {
		let style = `text-shark-400 text-sm sm:text-base md:text-xl ${hoverAniBlack}`;
		if (active === name) {
			style = `text-shark text-sm sm:text-base md:text-xl ${hoverAniBlack}`;
		}
		return style;
	};

	return (
		<nav
			className={`bg-transparent flex flex-col justify-center items-center z-10 top-0 min-w-full`}
		>
			<div className='container px-2 sm:py-0 w-full flex md:justify-between flex-col justify-center items-center'>
				<span className='flex gap-x-2 items-center'>
					<img
						src={gtsLogo}
						alt='Guytec Steel Logo'
						className='h-40 w-40'
					/>
					<h3 className='text-shark text-2xl font-bold cursor-default'>
						Guytec Steel Inc.
					</h3>
				</span>

				<ul className='gap-x-3 flex pb-1 w-full justify-evenly'>
					{navLinks.map((item) => (
						<ScrollIntoView
							key={item.id}
							selector={`#${item.name}`}
							alignToTop={true}
						>
							<li>
								<button
									className={getStyle(item.name)}
									onClick={() => setActive(item.name)}
								>
									{item.name}
								</button>
							</li>
						</ScrollIntoView>
					))}
				</ul>
			</div>
		</nav>
	);
};

export default Nav;

const hoverAniBlack =
	'hover:transition-colors hover:duration-300 hover:ease-in-out hover:text-shark relative before:content-[""] before:absolute before:block before:w-full before:h-[1px] before:bottom-0 before:left-0 before:bg-shark before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300';
