import { useEffect, useState } from "react";

export default function useReturnIntersecting(objRef) {
	const [isIntersecting, setIsIntersecting] = useState(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const objToWatch = entries[0];
				setIsIntersecting(objToWatch.isIntersecting);
			},
			{ threshold: [0.8, 1] }
		);
		observer.observe(objRef.current);
	}, [objRef]);

	return isIntersecting;
}
