import React from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

import "../styles/carousel.css";
import "@splidejs/react-splide/css";

const Carousel = () => {
	const splideOptions = {
		type: "loop",
		width: "100%",
		gap: "0 rem",
		drag: "free",
		pagination: false,
		arrows: false,
		perPage: 3,
		autoScroll: {
			pauseOnHover: false,
			pauseOnFocus: false,
			rewind: false,
			speed: 0.3,
		},
	};

	const logos = [
		// { id: 1, element: <AiscLogo className='h-full w-full' />, class: "" },
		{ id: 2, element: null, class: "mbe-logo h-full w-full" },
		{ id: 3, element: null, class: "nycsbs-logo h-full w-full" },
		{ id: 4, element: null, class: "dbe-logo h-full w-full" },
		{ id: 5, element: null, class: "iw40-logo h-full w-full" },
		{ id: 6, element: null, class: "iw361-logo h-full w-full" },
		{ id: 7, element: null, class: "sbe-logo h-full w-full" },
	];

	const cardStyle =
		"flex justify-center items-center w-[8rem] h-[6rem] sm:h-[8rem] px-2 lg:w-[20rem] py-1 rounded-lg bg-transparent text-oxford_blue my-2 ex-div";

	return (
		<Splide
			hasTrack={false}
			options={splideOptions}
			aria-label='Guytec Steel Credentials'
			extensions={{ AutoScroll }}
			className='splide-main'
		>
			<SplideTrack className='hover:cursor-grab active:cursor-grabbing'>
				{logos.map((logo) => (
					<SplideSlide key={logo.id}>
						<div className={cardStyle}>
							{logo.element === null ? (
								<div className={logo.class} />
							) : (
								logo.element
							)}
						</div>
					</SplideSlide>
				))}
			</SplideTrack>
		</Splide>
	);
};

export default Carousel;
