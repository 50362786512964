import React from "react";

const Map = () => {
	return (
		<div
			id='map'
			className='usaMap mx-auto md:h-1/2 md:w-1/2 xl:h-full xl:w-full'
		/>
	);
};

export default Map;
