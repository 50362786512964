import Main from "./components/Main";
import "./styles/App.css";

function App() {
	return (
		<>
			<Main />
		</>
	);
}

export default App;
