import { useForm, ValidationError } from "@formspree/react";

import "../styles/forms.css";

const MyForm = () => {
	const [state, handleSubmit, reset] = useForm(process.env.REACT_APP_FORM);
	if (state.succeeded) {
		return (
			<div className='w-full h-full flex flex-col gap-y-2 p-4 justify-center items-center'>
				<p className='text-shark text-center'>
					Thank you! Your message has been received.
				</p>
				<button
					onClick={reset}
					className='px-4 w-20 py-2 rounded-md bg-shark-200 text-shark hover:opacity-90'
				>
					Back
				</button>
			</div>
		);
	}

	return (
		<div className='flex flex-col h-full w-full'>
			<h2 className='text-shark pt-4 font-bold text-lg text-center whitespace-pre-wrap sm:text-xl md:text-2xl lg:text-3xl'>
				Contact Us
			</h2>
			<form
				onSubmit={handleSubmit}
				className='flex my-4 h-full gap-y-2 flex-col rounded-md p-2 w-full'
			>
				<div className='w-full flex md:flex-row flex-col gap-y-2 md:gap-y-0 justify-center gap-x-4'>
					<input
						id='name'
						type='text'
						name='name'
						placeholder='Name'
						required
					/>
					<ValidationError
						prefix='Name'
						field='name'
						errors={state.errors}
					/>
					<input
						id='phone'
						type='phone'
						name='phone'
						placeholder='Phone #'
						required
					/>
					<ValidationError
						prefix='Phone'
						field='phone'
						errors={state.errors}
					/>
				</div>
				<input
					id='email'
					type='email'
					name='email'
					placeholder='Email'
				/>
				<ValidationError
					prefix='Email'
					field='email'
					errors={state.errors}
				/>
				<input
					id='companyName'
					type='text'
					name='companyName'
					placeholder='Company Name'
					required
				/>
				<ValidationError
					prefix='companyName'
					field='companyName'
					errors={state.errors}
				/>
				<input
					id='subject'
					type='text'
					name='subject'
					placeholder='Subject'
					required
				/>
				<ValidationError
					prefix='Subject'
					field='subject'
					errors={state.errors}
				/>
				<textarea
					id='message'
					name='message'
					placeholder='Message'
					required
				/>
				<ValidationError
					prefix='Message'
					field='message'
					errors={state.errors}
				/>
				<button
					id='submit'
					type='submit'
					disabled={state.submitting}
					className='px-4 py-2 w-20 mx-auto flex 
					self-end justify-center rounded-md bg-orange text-white my-3 hover:opacity-90'
				>
					Submit
				</button>
			</form>
		</div>
	);
};

export default MyForm;
