import React, { useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import useReturnIntersecting from "../hooks/Hooks";

import "../styles/testimonials.css";
import { SectionHeader } from "./Services";
import {
	arnell,
	forte,
	forte2,
	minelli,
	monadnock,
	sca,
	top8,
	wdf,
} from "./staticData/TestimonialMessages";

const Testimonials = () => {
	const testimonialRef = useRef(null);
	const isIntersecting = useReturnIntersecting(testimonialRef);

	return (
		<section
			id='Testimonials'
			ref={testimonialRef}
			className={`${
				isIntersecting ? "sm:fadeIn" : "sm:fadeOut"
			} sm:my-8 relative w-full px-2`}
		>
			<SectionHeader headline='Client Experiences' />

			<BigTitle />

			<ClientMessages />
		</section>
	);
};

const BigTitle = () => (
	<div className='container mx-auto flex flex-col items-center gap-y-2 p-2'>
		<h2 className='text-shark font-bold text-lg text-center whitespace-pre-wrap sm:text-xl md:text-2xl lg:text-3xl'>
			What our clients have to say
		</h2>
		<p className='text-shark-700 max-w-[80ch] text-center'>
			Firsthand accounts of our clients' experiences with Guytec Steel.
			Discover how our commitment to efficiency, excellence, and customer
			satisfaction has made a difference in their projects.
		</p>
	</div>
);

const ClientMessages = () => {
	const [company, setCompany] = useState({
		name: "",
		jobTitle: "",
		company: "",
		logo: "",
	});
	const [authorStyle, setAuthorStyle] = useState("");

	useEffect(() => {
		if (company.name) {
			setAuthorStyle("fadeIn");
		}
	}, [company]);

	const resetState = () => {
		setTimeout(() => setAuthorStyle("fadeOut"), 3000);

		setTimeout(
			() => setCompany({ name: "", jobTitle: "", company: "", logo: "" }),
			4000
		);
	};

	const bigQuote = (
		<svg
			className='h-10 w-10 text-shark-700'
			aria-hidden='true'
			xmlns='http://www.w3.org/2000/svg'
			fill='currentColor'
			viewBox='0 0 18 14'
		>
			<path d='M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z' />
		</svg>
	);

	const details = [
		monadnock,
		() =>
			setCompany({
				name: "Luc Hains",
				jobTitle: ", Project Manager, ",
				company: "Monadnock Construction Inc.",
				logo: "monadnockLogo",
			}),
		3000,
		resetState,
		arnell,
		() =>
			setCompany({
				name: "Clifford Kitchner",
				jobTitle: ", Sr. Project Manager, ",
				company: "Arnell Construction Corp.",
				logo: "arnellLogo",
			}),
		3000,
		resetState,
		sca,
		() =>
			setCompany({
				name: "Sean Ye",
				jobTitle: ", Project Officer, ",
				company: "SCA",
				logo: "scaLogo",
			}),
		3000,
		resetState,
		minelli,
		() =>
			setCompany({
				name: "Joe Meli",
				jobTitle: ", Senior Project Manager, ",
				company: "Minelli Construction Co.",
				logo: "minelliLogo",
			}),
		3000,
		resetState,
		forte,
		() =>
			setCompany({
				name: "Paul Bathelemy",
				jobTitle: ", P.E, ",
				company: "Forte Construction Corp.",
				logo: "forteLogo",
			}),
		3000,
		resetState,
		top8,
		() =>
			setCompany({
				name: "Guy Bodenburg",
				jobTitle: ", Senior Project Manager, ",
				company: "Top8 Construction, F&T",
				logo: "top8Logo",
			}),
		3000,
		resetState,
		wdf,
		() =>
			setCompany({
				name: "Dan Zane",
				jobTitle: ", Project Manager, ",
				company: "WDF",
				logo: "wdfLogo",
			}),
		3000,
		resetState,
		forte2,
		() =>
			setCompany({
				name: "Dan Villandre",
				jobTitle: ", P.E, ",
				company: "Forte Construction Corp.",
				logo: "forteLogo",
			}),
		3000,
		resetState,
	];

	return (
		<div className='container mx-auto mt-4 px-2'>
			<div className='message-container md:min-h-[28rem] lg:min-h-[25rem] relative mx-auto mt-4 mb-4 flex flex-col gap-y-2 p-4 items-start justify-start lg:justify-center lg:items-center'>
				<div className='max-w-[80ch] w-full flex justify-start'>
					{bigQuote}
				</div>
				<div className='typewriter-cont pl-2 pr-1 border-l-4 border-orange bg-shark-50 flex h- w-full items-start max-w-[80ch] rounded-e-md lg:justify-start'>
					<TypeAnimation
						sequence={details}
						wrapper='span'
						cursor={true}
						repeat={Infinity}
						deletionSpeed={85}
						speed={75}
						style={{
							display: "inline-block",
							whiteSpace: "pre-line",
							color: "#1e2023",
						}}
					/>
				</div>

				<div className='message-author text-center lg:justify-center flex flex-col lg:flex-1 items-center justify-start w-full lg:h-full text-shark italic font-semibold'>
					<p className={authorStyle}>
						<span className='text-orange'>{company.name}</span>
						{company.jobTitle}
						{company.company}
					</p>
					<div
						className={`${authorStyle} ${company.logo} companyLogos`}
					/>
				</div>
			</div>
		</div>
	);
};

export default Testimonials;
