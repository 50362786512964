import React, { useEffect, useRef } from "react";
import useReturnIntersecting from "../hooks/Hooks";
import { isMobile } from "react-device-detect";
import { GoDotFill } from "react-icons/go";
import Map from "./Map";

import "../styles/locations.css";

const certData1 = [
	{ state: "AL", certs: ["DBE"] },
	{ state: "AK", certs: [] },
	{ state: "AZ", certs: ["DBE"] },
	{ state: "AR", certs: [] },
	{ state: "CA", certs: ["DBE"] },
	{ state: "CO", certs: ["DBE"] },
	{ state: "CT", certs: ["DBE"] },
	{ state: "DE", certs: ["DBE"] },
	{ state: "FL", certs: ["DBE"] },
	{ state: "GA", certs: [] },
	{ state: "HI", certs: ["DBE", "SBE"] },
	{ state: "ID", certs: ["DBE"] },
	{ state: "IL", certs: ["DBE"] },
	{ state: "IN", certs: ["DBE"] },
	{ state: "IA", certs: ["DBE"] },
	{ state: "KS", certs: ["DBE", "MBE"] },
	{ state: "KY", certs: ["DBE"] },
	{ state: "LA", certs: ["DBE"] },
	{ state: "ME", certs: ["DBE"] },
	{ state: "MD", certs: ["DBE", "SBE"] },
	{ state: "MA", certs: ["DBE"] },
	{ state: "MI", certs: ["DBE"] },
	{ state: "MN", certs: ["DBE", "MBE"] },
	{ state: "MS", certs: ["DBE"] },
	{ state: "MO", certs: ["DBE"] },
];

const certData2 = [
	{ state: "MT", certs: ["DBE"] },
	{ state: "NE", certs: ["DBE"] },
	{ state: "NV", certs: ["DBE"] },
	{ state: "NH", certs: ["DBE"] },
	{ state: "NJ", certs: ["DBE", "SBE", "MBE"] },
	{ state: "NM", certs: [] },
	{ state: "NY", certs: ["DBE", "SBE", "MBE"] },
	{ state: "NC", certs: ["DBE"] },
	{ state: "ND", certs: ["DBE"] },
	{ state: "OH", certs: ["DBE"] },
	{ state: "OK", certs: ["DBE"] },
	{ state: "OR", certs: ["DBE", "MBE"] },
	{ state: "PA", certs: ["DBE", "SBE", "MBE"] },
	{ state: "RI", certs: ["DBE"] },
	{ state: "SC", certs: ["DBE"] },
	{ state: "SD", certs: ["DBE"] },
	{ state: "TN", certs: ["DBE"] },
	{ state: "TX", certs: ["DBE"] },
	{ state: "UT", certs: ["DBE"] },
	{ state: "VT", certs: ["DBE"] },
	{ state: "VA", certs: [] },
	{ state: "WA", certs: ["DBE"] },
	{ state: "WV", certs: ["DBE"] },
	{ state: "WI", certs: ["DBE"] },
	{ state: "WY", certs: ["DBE"] },
];

const Locations = ({ setActive }) => {
	const locationRef = useRef(null);
	const isIntersecting = useReturnIntersecting(locationRef);

	useEffect(() => {
		if (isIntersecting) {
			setActive("Locations");
		}
	}, [setActive, isIntersecting]);

	return (
		<>
			<section
				id='Locations'
				ref={locationRef}
				className='locations-cont relative w-full my-8'
			>
				<div className='container p-2 flex xl:flex-row flex-col mx-auto'>
					<LocationsLeft isMobile={isMobile} />
					<div className='locationsRightMap py-2 xl:bg-transparent bg-shark-700 flex-1 h-full w-full rounded-b-md xl:rounded-none'>
						{isMobile ? (
							<LocationsRightMobile />
						) : (
							<LocationsRightMap />
						)}
					</div>
				</div>
			</section>
		</>
	);
};

const LocationsLeft = ({ isMobile }) => (
	<div className='locationsLeft xl:bg-transparent bg-orange flex flex-col justify-center items-center py-2 flex-1 w-full rounded-t-md xl:rounded-none'>
		<div className='xl:min-w-full p-4 gap-y-2 flex flex-col text-white'>
			<h2 className='font-bold text-lg whitespace-pre-wrap sm:text-xl md:text-2xl lg:text-3xl'>
				Where we operate
			</h2>
			<p className='font-semibold'>
				Guytec Steel proudly operates across the United States,
				providing our specialized structural services. With
				certifications including Minority Business Enterprise,
				Disadvantaged Business Enterprise, and Small Business Enterprise
				in multiple states, we are committed to delivering excellence in
				every project we undertake.
			</p>
			<div className='h-2' />
			<p className='font-semibold'>
				{isMobile
					? ""
					: "Hover over each state on the map to discover the certifications we hold in that region. Our extensive coverage ensures that we can meet your structural steel needs with precision and compliance wherever your project may be located."}
			</p>
		</div>
	</div>
);

const LocationsRightMap = () => <Map />;

const LocationsRightMobile = () => {
	const mbeDot = <GoDotFill className='inline text-[#4CC9F0]' />;
	const dbeDot = <GoDotFill className='inline text-[#0FFF95]' />;
	const sbeDot = <GoDotFill className='inline text-[#FF5A5F]' />;

	const certFormatter = (certList) => {
		return certList.map((c) => (
			<li
				key={c.state}
				className='text-white text-sm flex gap-x-3 items-center'
			>
				<span className='mr-3 w-4'>{c.state}</span>
				<span className='flex gap-x-1 items-center'>
					{c.certs.includes("MBE") && mbeDot}{" "}
					{c.certs.includes("DBE") && dbeDot}{" "}
					{c.certs.includes("SBE") && sbeDot}
				</span>
			</li>
		));
	};

	return (
		<div className='flex flex-col w-full justify-center items-center px-2 gap-y-3'>
			<ul className='flex w-full justify-evenly text-white'>
				<li className='flex items-center gap-x-2'>{mbeDot} MBE</li>
				<li className='flex items-center gap-x-2'>{dbeDot} DBE</li>
				<li className='flex items-center gap-x-2'>{sbeDot} SBE</li>
			</ul>

			<div className='flex w-full h-fit'>
				<div className='border-r border-r-orange w-full h-full'>
					<ul className='flex flex-col gap-y-1'>
						{certFormatter(certData1)}
					</ul>
				</div>
				<div className='w-full h-full'>
					<ul className='flex flex-col pl-2 gap-y-1'>
						{certFormatter(certData2)}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Locations;
