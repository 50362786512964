import React, { useEffect, useRef } from "react";
import useReturnIntersecting from "../hooks/Hooks";
import { ReactComponent as BobSig } from "../assets/svg/c-sattar.svg";

import "../styles/about.css";
import { SectionHeader } from "./Services";

const About = ({ setActive }) => {
	const aboutRef = useRef(null);
	const isIntersecting = useReturnIntersecting(aboutRef);

	useEffect(() => {
		if (isIntersecting) {
			setActive("About");
		}
	}, [setActive, isIntersecting]);

	return (
		<section
			ref={aboutRef}
			id='About'
			className='relative flex flex-col gap-y-2 w-full mb-8'
		>
			<SectionHeader headline='The Journey' />

			<div className='aboutUsContent flex flex-col gap-y-3 sm:flex-row container mx-auto sm:gap-x-4 p-2'>
				<AboutUsLeft />

				<AboutUsRight />
			</div>
		</section>
	);
};

const AboutUsLeft = () => (
	<div className='flex flex-col'>
		<h2 className='text-shark font-bold text-lg text-center whitespace-pre-wrap sm:text-xl md:text-2xl lg:text-3xl'>
			About us
		</h2>
		<p className='text-shark-700 max-w-[80ch] text-center'>
			Established in 2014, Guytec Steel builds upon over four decades of
			industry expertise brought by our founder, who has accumulated 46
			years of invaluable experience in steel erection. Based in the heart
			of New York City, we have swiftly become a trusted name in the
			construction sector, specializing in the precise and efficient
			erection of structural steel for a wide array of projects. From
			iconic landmarks to essential infrastructure, our skilled team
			leverages extensive knowledge and a steadfast commitment to quality
			and safety. At Guytec Steel, we are dedicated to not only shaping
			the skyline of NYC but also setting benchmarks in the industry
			through our unparalleled craftsmanship and dedication to excellence.
		</p>
	</div>
);

const AboutUsRight = () => (
	<div className='flex flex-col mx-auto items-center gap-y-2'>
		<h2 className='text-shark font-bold text-lg text-center whitespace-pre-wrap sm:text-xl md:text-2xl lg:text-3xl'>
			<span className='text-orange'>46 years</span> of experience!
		</h2>
		<BobSig className='mx-auto' />
		<p className='mx-auto text-shark-700 italic'>
			Casel Sattar, aka <span className='text-orange'>"Bob"</span>,
			CEO/President
		</p>
	</div>
);

export default About;
