import React, { useEffect, useRef } from "react";
import useReturnIntersecting from "../hooks/Hooks";

import "../styles/services.css";

const Services = ({ setActive }) => {
	const servicesRef = useRef(null);
	const isIntersecting = useReturnIntersecting(servicesRef);

	useEffect(() => {
		if (isIntersecting) {
			setActive("Services");
		}
	}, [setActive, isIntersecting]);

	return (
		<section
			ref={servicesRef}
			id='Services'
			className='relative w-full gap-y-2 mt-8 services-main flex flex-col items-center px-2'
		>
			<SectionHeader headline={"What We Do"} />

			<BigTitle />

			<ServicesCards />
		</section>
	);
};

export const SectionHeader = ({ headline }) => {
	const divLine = <div className='header-decorator h-[1px] w-32 bg-orange' />;

	return (
		<div className='container mx-auto my-2 flex flex-col'>
			<div className='flex items-center w-full justify-center'>
				{divLine}
				<h3 className='whitespace-nowrap font-semibold text-orange mx-1'>
					{headline}
				</h3>
				{divLine}
			</div>
		</div>
	);
};

const BigTitle = () => {
	return (
		<div className='container mx-auto w-full flex flex-col items-center gap-y-2 p-2'>
			<h2 className='text-shark font-bold text-lg text-center whitespace-pre-wrap sm:text-xl md:text-2xl lg:text-3xl'>
				Our professional construction services
			</h2>
			<p className='text-shark-700 max-w-[80ch] text-center'>
				We specialize in providing comprehensive solutions tailored to
				meet the diverse needs of construction projects nationwide. Our
				core services include:
			</p>
		</div>
	);
};

const ServicesCards = () => {
	const servCardStyle = "h-full w-full object-center object-cover rounded-md";

	const cardTextStyle =
		"absolute z-20 text-white font-bold mt-4  sm:text-2xl top-0 left-1/2 -translate-x-1/2 tracking-wide text-center";

	const darkBlock = (
		<div className='absolute h-full w-full z-10 rounded-md top-0 left-0 bg-black/65 hover:bg-black/75' />
	);

	return (
		<div className='flex flex-col container mx-auto gap-y-2 px-2 max-w-[60rem]'>
			<div className='steel-erection rounded-md h-80 relative w-full'>
				<img
					src='https://guytecsteel.nyc3.digitaloceanspaces.com/servicePics/steelErection3.webp'
					alt='The steel framing of a building under construction'
					className={`${servCardStyle}`}
				/>
				{darkBlock}
				<p className={cardTextStyle}>STRUCTURAL STEEL ERECTION</p>
			</div>
			<div className='flex justify-center gap-x-2 items-center'>
				<div className='precast-concrete h-80 relative w-full rounded-md flex-1'>
					<img
						src='https://guytecsteel.nyc3.digitaloceanspaces.com/servicePics/bc2.webp'
						alt='Precast concrete panel being hoisted by a crane for installation'
						className={`${servCardStyle}`}
					/>
					{darkBlock}
					<p className={cardTextStyle}>PRECAST PANELS</p>
				</div>
				<div className='fabrication h-80 relative w-full rounded-md flex-1'>
					<img
						src='https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/tangram%20plaza.webp'
						alt='Two welders fabricating steel stairs on a job-site'
						className={`${servCardStyle}`}
					/>
					{darkBlock}
					<p className={cardTextStyle}>FABRICATION</p>
				</div>
			</div>
		</div>
	);
};

export default Services;
