import React, { useState } from "react";
import Nav from "./Navbar";
import Hero from "./Hero";
import Carousel from "./Carousel";
import Services from "./Services";
import About from "./About";
import Locations from "./Locations";
import Gallery from "./Gallery";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import FooterComp from "./Footer";

import "../styles/main.css";

const Main = () => {
	const [active, setActive] = useState(null);

	return (
		<main className='min-h-screen w-full relative flex flex-col items-center'>
			<Nav
				active={active}
				setActive={setActive}
			/>

			<Hero
				active={active}
				setActive={setActive}
			/>
			<Carousel />
			<Services setActive={setActive} />
			<About setActive={setActive} />
			<Locations setActive={setActive} />
			<Gallery setActive={setActive} />
			<Testimonials />
			<Contact setActive={setActive} />

			<FooterComp />
		</main>
	);
};

export default Main;
