import React, { useEffect, useRef } from "react";
import useReturnIntersecting from "../hooks/Hooks";
import MyForm from "./Form";
import { hoverAni } from "./Footer";

import "../styles/contact.css";

const Contact = ({ setActive }) => {
	const contactRef = useRef(null);
	const isIntersecting = useReturnIntersecting(contactRef);

	useEffect(() => {
		if (isIntersecting) {
			setActive("Contact");
		}
	}, [setActive, isIntersecting]);

	return (
		<section
			ref={contactRef}
			id='Contact'
			className='h-[50rem] w-full flex flex-col bg-shark-700'
		>
			<div className='container px-2 h-full flex flex-col py-4 justify-center mx-auto'>
				<Address />
				<ContactInfo />
			</div>
		</section>
	);
};

const Address = () => (
	<iframe
		title='Guytec Steel Inc. location'
		className='flex-1 rounded-t-md'
		src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d424.4444171418617!2d-73.90217247206489!3d40.69388374854517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25c254b5c915b%3A0x9586f84b61f9f4dd!2s1106%20Wyckoff%20Ave%2C%20Ridgewood%2C%20NY%2011385!5e0!3m2!1sen!2sus!4v1719499147478!5m2!1sen!2sus'
		width='100%'
		height='100%'
		allowFullScreen=''
		loading='lazy'
		referrerPolicy='no-referrer-when-downgrade'
	></iframe>
);

const ContactInfo = () => {
	return (
		<div className='contact-info-cont relative h-full bg-white w-full flex-1 rounded-b-md flex items-center justify-center'>
			<ContactLeft />
			<div className='contact-divider h-[85%] w-[1px] bg-orange' />
			<ContactRight />
		</div>
	);
};

const ContactLeft = () => (
	<div className='flex-1 flex h-full w-full justify-center px-2'>
		<MyForm />
	</div>
);

const ContactRight = () => (
	<div className='flex-1 flex flex-col h-full w-full py-4 px-2'>
		<h2 className='text-shark font-bold text-lg text-center whitespace-pre-wrap sm:text-xl md:text-2xl lg:text-3xl'>
			Guytec Steel Inc.
		</h2>
		<p className='mx-auto text-shark-700 text-center'>
			1106 Wyckoff Ave, Ridgewood, NY 11385
		</p>

		<div className='flex flex-col py-6 h-full items-start mx-auto text-shark-700 gap-y-1'>
			<a
				href='mailto:info@guytecsteel.com'
				aria-label='Link to send email to Guytec Steel'
				className={hoverAni}
			>
				info@guytecsteel.com
			</a>
			<p>
				Office:{" "}
				<a
					href='tel:+17188213500'
					aria-label="Guytec Steel's phone number"
					className={hoverAni}
				>
					718-821-3500
				</a>
			</p>
			<p>
				Fax:{" "}
				<a
					href='tel:+17188213700'
					aria-label="Guytec Steel's fax number"
					className={hoverAni}
				>
					718-821-3700
				</a>
			</p>
		</div>
	</div>
);

export default Contact;
