import React from "react";
import gtsLogo from "../assets/svg/gtsLogo.svg";
import { Footer } from "flowbite-react";
import {
	FaSquareFacebook,
	FaMeta,
	FaSquareInstagram,
	FaTwitter,
	FaLinkedin,
} from "react-icons/fa6";

import "../styles/footer.css";

const FooterComp = () => {
	const footerTheme = {
		root: {
			base: "rounded-none bg-white text-[#1877F2]",
			brand: {
				span: "text-orange, dark:text-orange",
			},
		},
	};

	const curDate = new Date().getFullYear();

	return (
		<Footer
			container
			theme={footerTheme}
		>
			<div className='w-full text-center container mx-auto'>
				<div className='w-full justify-between sm:flex sm:items-center sm:justify-between'>
					<Footer.Brand
						href='https://guytecsteel.com'
						target='_blank'
						rel='noreferrer'
						src={gtsLogo}
						alt='Guytec Steel Logo'
						name='Guytec Steel Inc.'
						className='brand-link mx-auto'
					/>
					<Footer.LinkGroup className='socials text-shark-300 FOOBAR dark:text-shark-300'>
						<SocialLinks />
					</Footer.LinkGroup>
				</div>
				<Footer.Divider className='my-6 w-full border-shark-400 dark:border-shark-400 sm:mx-auto lg:my-8' />
				<div className='text-shark-400'>
					Site developed by{" "}
					<a
						href='https://www.linkedin.com/in/malikomarswe/'
						target='_blank'
						rel='noreferrer'
						aria-label="Malik Omar's linkedin profile"
						className={`${hoverAniLI} relative`}
					>
						Malik Omar{" "}
						<FaLinkedin className='inline -translate-y-2 top-0 right-0' />
					</a>
				</div>
				<Footer.Copyright
					href=''
					by='Guytec Steel™ All Rights Reserved'
					year={curDate}
					className='text-shark-400 dark:text-shark-400'
				/>
			</div>
		</Footer>
	);
};

export default FooterComp;

export const hoverAni =
	'hover:transition-colors hover:duration-300 hover:ease-in-out hover:text-orange relative before:content-[""] before:absolute before:block before:w-full before:h-[1px] before:bottom-0 before:left-0 before:bg-orange before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300';

const hoverAniLI =
	'hover:transition-colors hover:duration-300 hover:ease-in-out hover:text-[#0077B5] relative before:content-[""] before:absolute before:block before:w-full before:h-[1px] before:bottom-0 before:left-0 before:bg-[#0077B5] before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300';

const hoverAniFB =
	'hover:transition-colors hover:duration-300 hover:ease-in-out  relative before:content-[""] before:absolute before:block before:w-full before:h-[1px] before:bottom-0 before:left-0 before:bg-[#1877F2] before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300';

const hoverAniMeta =
	'hover:transition-colors hover:duration-300 hover:ease-in-out relative before:content-[""] before:absolute before:block before:w-full before:h-[1px] before:bottom-0 before:left-0 before:bg-[#0081FB] before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300';

const hoverAniInsta =
	'hover:transition-colors hover:duration-300 hover:ease-in-out relative before:content-[""] before:absolute before:block before:w-full before:h-[1px] before:bottom-0 before:left-0 before:bg-[#F56040] before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300';

const hoverAniTwitter =
	'hover:transition-colors hover:duration-300 hover:ease-in-out  relative before:content-[""] before:absolute before:block before:w-full before:h-[1px] before:bottom-0 before:left-0 before:bg-[#1DA1F2] before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300';

export const SocialLinks = () => {
	const logos = [
		{
			id: 1,
			element: <FaSquareFacebook className='h-full w-full' />,
			linkTo: "https://www.facebook.com/profile.php?id=100054283819914",
			style: `${hoverAniFB} text-[#1877F2]`,
			label: "Guytec Steel's Facebook page",
		},
		{
			id: 2,
			element: <FaMeta className='h-full w-full' />,
			linkTo: "https://www.facebook.com/profile.php?id=100054283819914",
			style: `${hoverAniMeta} text-[#0081FB]`,
			label: "Guytec Steel's Meta profile",
		},
		{
			id: 3,
			element: <FaSquareInstagram className='h-full w-full' />,
			linkTo: "https://www.instagram.com/guytecsteel/",
			style: `${hoverAniInsta} text-[#F56040]`,
			label: "Guytec Steel's Instagram profile",
		},
		{
			id: 4,
			element: <FaTwitter className='h-full w-full' />,
			linkTo: "https://x.com/guytecsteel",
			style: `${hoverAniTwitter} text-[#1DA1F2]`,
			label: "Guytec Steel's Twitter profile",
		},
	];

	return logos.map((logo) => (
		<a
			key={logo.id}
			href={logo.linkTo}
			target='__blank'
			aria-label={logo.label}
			className={`${logo.style} h-8 py-1 me-4 last:mr-0 md:mr-6`}
		>
			{logo.element}
		</a>
	));
};
