export const monadnock =
	"Guytec executed their work on time and in the middle of the winter. When requested, they provided additional personnel to make up for the weather delays which permitted the schedule to remain on track. If the opportunity permits, I would work with Guytec in the future.";

export const arnell =
	'Guytec Steel has performed Structural Steel erection for us on several SCA School Construction Projects. They have continually fulfilled our requirements and expectations. We will use them again, in fact they are our "most favored" contractor. We have no reservations in recommending them for any future work.';

export const sca =
	"The firm has successfully completed structural steel erection on time for the current project at PS101 Brooklyn, for the School Construction Authority. It was a pleasure working with Guytec Steel, their professionalism and compliance for what needs to be done for the construction goal. Therefore, I highly recommend Guytec Steel to participate and continue to demonstrate their performance to other projects.";

export const minelli =
	"Guytec Steel is a very good, dependable steel erector/fabricator performing superior quality craftsmanship at the highest standard. We here at Minelli have used them on many projects with nothing but outstanding results with the latest project being LaGuardia Community College for DASNY which they completed 1 month ahead of schedule.";

export const forte =
	"This project (F.I.T SUNY) is on an accelerated schedule and Guytec Steel has manned the project to fit our needs and to meet this accelerated schedule safely and on budget. Guytec Steel has provided an impeccable service from submittals to the topping-off of this new midrise building. They have shown nothing but high degrees of professionalism and commitment to the project, construction management team and owner.";

export const top8 =
	"The team we had onsite daily were extremely competent and hard working. They maintained the schedule we gave them to follow without issue. If they fell behind for any reason, they worked overtime or increased manpower the next day to get back on track without question. We look forward to working with them on our next project.";

export const wdf =
	"To date, Guytec Steel has been timely with the submission of required paperwork and shop drawings. Work is being performed in a high quality and professional manner. We look forward to the successful completion of this project (Baruch College Phase 1A) and will consider Guytec for other projects in the future.";

export const forte2 = `As Project Manager of PS487, I had the pleasure of working w/Guytec Steel for the project's scope of Precast Panel Erection. The project milestone of achieving a watertight structure prior to the winter season was accomplished with Guytec Steel playing a major role in the process. The on-site supervision for the project was amenable and utilized detailed planning to complete erection within the project schedule. This outlook helped achieve completion of Guytec's contract work without any safety violations or recordable incidents.\nIf this project is a typical example of Guytec Steel's ability to successfully complete a project under firm deadlines in a safe and proficient manner, then I would recomment the firm to any prospective client.`;
