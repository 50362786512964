import React, { useEffect, useRef } from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import { TypeAnimation } from "react-type-animation";
import useReturnIntersecting from "../hooks/Hooks";

import "../styles/hero.css";

const Hero = ({ active, setActive }) => {
	const homeRef = useRef(null);
	const isIntersecting = useReturnIntersecting(homeRef);

	useEffect(() => {
		if (isIntersecting) {
			setActive("Home");
		}
	}, [setActive, isIntersecting]);

	const details = [
		"Shaping Skylines",
		3000,
		"Building Infrastructure",
		3000,
		"Forging Excellence",
		3000,
		"Constructing Legacies",
		3000,
		"Crafting Urbanscapes",
		3000,
	];

	return (
		<section
			id='Home'
			ref={homeRef}
			className='relative hero-main min-h-screen w-full flex items-center justify-center'
		>
			<ParallaxBanner
				layers={[
					{
						image: "https://guytecsteel.nyc3.digitaloceanspaces.com/carouselWebP/tangramPlazaRigging.webp",
						speed: -20,
						children: (
							<div className='bg-black/30 h-full w-full'></div>
						),
					},
				]}
				className='aspect-[2/1] parallax-container min-h-screen'
			/>
			<div className='h-72 absolute container mx-auto flex flex-col items-start justify-end text-white gap-y-2 px-2'>
				<h1 className='sm:text-4xl text-2xl px-2 font-semibold tracking-[-1.6px]'>
					<TypeAnimation
						sequence={details}
						wrapper='span'
						cursor={true}
						repeat={Infinity}
						deletionSpeed={40}
						speed={40}
						style={{
							display: "inline-block",
							whiteSpace: "pre-line",
						}}
					/>
				</h1>
				<p className='px-2 font-semibold tracking-[-1.6px] text-xl'>
					est. 2014
				</p>
			</div>
		</section>
	);
};

export default Hero;
